// Alert Box & Menu Shifting on Mobile
var scrollTop = jQuery(window).scrollTop();
var elementOffset = jQuery('.nav-mobile-row').offset().top;
var distance = (elementOffset - scrollTop);
var newTop = distance +"px";

// Hide Alert Box
jQuery(".alert-row").hide();

// Check if User already acknowledged Alert Box
if(!localStorage["alert_dismissed"]) {

    // Reveal Alert Box
    jQuery(".alert-row").delay(2500).slideDown(1500, function(){
    });

    // Shift Menu
    jQuery(".hamburger").delay(2500).animate({
      "top":newTop
    }, 1500);

    // User Closes Alert Box
    jQuery("#alert-close").click(function (event){
      event.preventDefault();
      jQuery(".alert-row").slideUp(750);
      jQuery(".hamburger").animate({
        "top":"10px"
      }, 750);
      localStorage["alert_dismissed"] = true;
    });

    // User Clicks Alert Link
    jQuery(".alert-row a").click(function() {
      localStorage["alert_dismissed"] = true;
    });

    // Close Alert if Menu Opens
    jQuery(".hamburger").click(function (){
      jQuery(".alert-row").slideUp(250);
      jQuery(".hamburger").animate({
        "top":"10px"
      }, 250);
    });
} // End of IF

// Curent Page Indicator
jQuery(function() {
  // Get current url
  var url = window.location.href;

  // Apply active class where needed
  jQuery(".desktop-nav .nav-primary a").each(function(){
    if ( url === (this.href)) {
      jQuery(this).addClass("menu-item-active");
    }
  });
});

// Donate is Special
jQuery("a:contains('Donate')").css("color","#FF6800");

// To Top Button
var viewHeight = jQuery(window).height(); //threshold height where to-top button appears or disappears
var toTopVisible = false; //set when visibility of to-top button is toggled to help prevent rapid reversals of animation

// to-top button is clicked, take scroll back to top of page
jQuery('#to-top').on('click', function(){
  jQuery('body,html').animate({
    scrollTop: 0
  },750);
});

// check for scroll position against set threshold (viewHeight) when user scrolls
jQuery(window).on('scroll', function(){

  // scroll is below threshold AND button is not already visible
  if (jQuery(this).scrollTop() >= viewHeight && !toTopVisible) {
    toggleToTopBtn(20, true);
  }
  // scroll is above threshold AND button is currently visible
  else if (jQuery(this).scrollTop() < viewHeight && toTopVisible) {
    toggleToTopBtn(-120, false);
  }
});

// show/hide to-top button
function toggleToTopBtn(pos, setVar) {
  toTopVisible = setVar;
  jQuery('#to-top').show().animate({
    right: pos
  }, 1000);
}
